import React, { createContext, useContext, useCallback } from 'react'
import _ from 'lodash'


const SwaggerDefinitionsContext = createContext({})

export const SwaggerDefinitionsProvider = (props) => {
  const { children, definitions, filterContext, filterOnFn } = props



  const findDefinitionByUrl = useCallback((url) => {
    //console.log('finddefinitions', url)
    return _.find(definitions.definitionList, (definition) => definition?.apiFile?.url === url)
  }, [definitions.definitionList])


  const context = {
    definitions,
    findDefinitionByUrl,
  }
 

  return (
    <SwaggerDefinitionsContext.Provider 
            value={context} 
            children={children} 
            filterContext={filterContext}
            filterOnFn={filterOnFn}
            />
  )
}

export const SwaggerDefinitionsConsumer = SwaggerDefinitionsContext.Consumer
export const SwaggerDefinitions = SwaggerDefinitionsContext

export const useSwaggerDefinitions = () => useContext(SwaggerDefinitionsContext)

import React, { useMemo} from "react";

import APILink from "./apilink.js";
import SearchBar from "./searchmenu";
import _ from 'lodash'
import { useSwaggerDefinitions } from "../contexts/swaggerdefintionscontext.js";


const Sidebar = (props) => {
  const { organizationConfig } = props
  const { definitions } = useSwaggerDefinitions()
  

  const apiLinks = useMemo(() => {
    return _.map(definitions.definitionList, (definition, i) => {
      return (
        <APILink
          key={i}
          apiLinkData={definition}
        />
      )
    })
  }, [definitions.definitionList])

  return (
    <div className="side-bar">
      <div className="side-bar-header">
        <h1>{organizationConfig.displayName}</h1>
      </div>
      <div className="side-bar-body">
        <SearchBar />
        {apiLinks}
      </div>
    </div>
  );
};


export default Sidebar;




import React, { useEffect, useRef } from 'react'
import SwaggerUI from "swagger-ui";
//import "swagger-ui-react/swagger-ui.css";


const SwaggerUIComponent = (props) => {
  //useDebugPropChanges(props)
  const { id, className, style, ...rest } = props
  const ref = useRef(null)
  
//console.log('Swagger-UI', rest, ref)
  useEffect(() => {
   
    //ref?.current.scrollTo(0,0)
    
    SwaggerUI({
      domNode: ref.current,
      ...rest,
    })
    //console.log('swagger-ui rendered')
  }, [rest, ref])

  return (
    <div
      ref={ref}
      id={id}
      className={className}
      style={style}
    />
  )
}

export default SwaggerUIComponent

function logPropDifferences(newProps, lastProps) {
  const allKeys = new Set(Object.keys(newProps)).add(Object.keys(lastProps));
  allKeys.forEach(key => {
    const newValue = newProps[key];
    const lastValue = lastProps[key];
    if (newValue !== lastValue) {
      console.log('New Value: ', newValue);
      console.log('Last Value: ', lastValue);
    }
  });
}

function useDebugPropChanges(newProps) {
  const lastProps = useRef();
  // Should only run when the component re-mounts
  useEffect(() => {
    console.log('Mounted', lastProps);
  }, []);
  if (lastProps.current) {
    logPropDifferences(newProps, lastProps.current);
  }
  lastProps.current = newProps;
}
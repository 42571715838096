import React from "react";
import { Link } from "react-router-dom";

const APILink = (props) => {
  const { apiLinkData } = props

  return (
    <Link to={apiLinkData?.apiFile?.url} className="api-link">
      <div>
        {apiLinkData?.apiFile?.apiName}
      </div>
    </Link>
  )
};

export default APILink;

import React, { useState, useEffect } from "react";
import "./app.css";
import "swagger-ui-react/swagger-ui.css";
//import "../node_modules/swagger-ui/dist/swagger-ui.css";
//import { configure } from '@testing-library/react';

import importFiles from "./swaggers";
import Config from "./config.json";

import Sidebar from "./components/sidebar.js";
import { Router} from 'react-router-dom'
import { createBrowserHistory } from "history";

import Routing from "./Routing";
import { SwaggerDefinitionsProvider } from "./contexts/swaggerdefintionscontext";
import _ from 'lodash';

const history = createBrowserHistory();


const App = () => {
//console.log('APP')
  const updState = (x) => {
    //console.log('x=', x)
    setState(state => ({...state, definitionList: x}))};
  const updBegin = (x) => {
    //console.log('updBegin', x)
    setState(state => ({...state, beginList: x}))};
  const filterOn = (input) => {
    setState(state => ({...state, filterOff: input}))
  };
  const resState = () => {
    setState(state => ({...state, definitionList: state.beginList}))}
  const [state, setState] = useState({
    definitionList: [],
    beginList: [],
    filterOff: true,
    filterOnFn: filterOn,
    filterContext: updState,
    restoreContext: resState})

  const [organizationConfig] = useState(Config.orgData)
  


    
  useEffect(() => {
    async function fetchData() {
      //console.log('importfiles2 start')
      const  firstState = await importFiles()
      //console.log('importfiles2 end')
  
  
      //console.log('data', data)
      const initialState = _.sortBy(firstState, 'apiFile.apiName')
      //console.log('initialstate',state.definitionList,initialState)
      if (state.filterOff && !(_.isEqual(state.definitionList,initialState))){
        
        
        //updState(initialState)
        updBegin(initialState)
        resState();
      };
      //console.log('fetchdata end')
      return
    }
    fetchData();
    //console.log('app rendered', state)
    return () => {
      
    }
    
  }, [])
 
 
  return (
    <div className="App">

        <SwaggerDefinitionsProvider definitions={state}>
          <Router history={history}>
            <Sidebar organizationConfig={organizationConfig} />
            
              <Routing initLocation={history.location.pathname}/>
            
            
          </Router>
        </SwaggerDefinitionsProvider>
 
      
    </div>
  )
}

export default App;

import React, {useState} from "react";
//import { useSwaggerDefinitions } from "../contexts/swaggerdefintionscontext.js";
import { SwaggerDefinitions } from "../contexts/swaggerdefintionscontext.js";
import _ from 'lodash';



const SearchBar = (props) => {

    const [fieldState, setFieldState] = useState('');

   
  
    return (
        
            
        <SwaggerDefinitions.Consumer>
            {({definitions}) => (
                
                <form className='inputWithButton' autoComplete="off">
                  <div className='inputWithButton'>  
                    <input
                        type="text"
                        id="header-search"
                        placeholder="Search menu"
                        name="s"
                        value={fieldState}
                        
                        onChange={(e) => {
                            //definitions.restoreContext();
                            
                            setFieldState(e.target.value)
                            
                            //console.log('SEARCH', definitions.definitionList.length, definitions.beginList.length)
                            const newList = _.filter(definitions.beginList, 
                                (i) => {
                                    const sStr = i?.apiFile?.apiName.toLowerCase();
                                    
                                return sStr.includes(e.target.value)
                            })
                            
                            definitions.filterContext(newList)
                            definitions.filterOnFn(false)
                            
                        }}
                        
                        autoFocus
                        autoComplete="off"
                    />
                    <button
                        type='button'
                        onClick={(e)=>{
                            definitions.restoreContext();
                            window.scrollTo(0,0);
                            definitions.filterOnFn(true)
                            setFieldState('');
                            e.currentTarget.blur();
                            
                            
                        }}
                        

                    >&#10006;</button>
                  </div> 
                    {/*<button onClick={definitions.restoreContext()}>
                        Clear filter
                    </button>*/}

                    {/*console.log(definitions)*/}
                </form>
            )}
        </SwaggerDefinitions.Consumer>        



       

  )};

  export default SearchBar;
import React, { useMemo } from 'react'
import SwaggerUIComponent from "../components/swaggerui";
import { useSwaggerDefinitions } from '../contexts/swaggerdefintionscontext';



const SwaggerPage = (props) => {
  const { match} = props
  const { findDefinitionByUrl } = useSwaggerDefinitions()

 

  const currentDefinition = useMemo(() => {
    return findDefinitionByUrl(match.params?.page)
  }, [match])

 //console.log('current', currentDefinition?.apiFile?.definitionLink)
  if (!currentDefinition) {
    
    return (
      <div id="api-data">
        No definitions
      </div>
    );
  } else {
    return (
    
      <SwaggerUIComponent
        id="api-data"
        spec={currentDefinition?.apiFile?.definitionLink}
        deepLinking
      />
    )
  }

 


  


}

export default SwaggerPage

import _ from 'lodash'

import definitions from "./descriptions.json"

const importFiles = async () => {
  try {
  //  console.log('importfiles start')
    const sConfig = await Promise.all(_.map(definitions, async (item) => {
      const module = await import("./"+item.apiFile.fileName)
    
    
      const { apiFile } = item
      
      return {
        ...item,
        apiFile: {
          ...apiFile,
          definitionLink: module.default,
          url: apiFile.url || _.snakeCase(apiFile.apiName),
        }
      }
    
    })) 
    //console.log('importfiles end')
      return sConfig
  } catch (error) {
    console.error(error)
  }

}


export default importFiles;

import React, {  useEffect } from 'react'
import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { useSwaggerDefinitions } from './contexts/swaggerdefintionscontext';



import SwaggerPage from './pages/swaggerpage'


const Routing = (props) => {

  
    const { definitions } = useSwaggerDefinitions();
    let location = useLocation();
    
    const firstDefinitionUrl =  () => {

      if (definitions?.definitionList.length < 1) {
        return ''
      }
      return definitions?.definitionList?.[0]?.apiFile?.url
    }   
    const history = useHistory();
    
    useEffect(() => {
      
      //find first url if url is not specified
      if ((location.pathname && location.pathname === '/') || !(definitions.filterOff)) {
        history.push('/'+firstDefinitionUrl()) ;

      }
      
    },[definitions.definitionList])
    
    //  
   

  return (
    <>
      <Switch location={location}>
        <Route path="/:page" component={SwaggerPage} />
        <Route  path="/" >
          
          <Redirect to={firstDefinitionUrl()} />
        </Route>
      </Switch>
    </>
  )
}

export default Routing
